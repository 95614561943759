import React, { useCallback, useEffect, useState } from 'react';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import { CartTotal } from 'components/easy-refill/cart-total.component';

import { EXPEDITED_SHIPPING_COST } from 'util/cart';

//  misc ui-kit
import Button from 'ui-kit/button/button';

import './review.style.scss';
import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';
import {
    easyRefillAjudicatedRxsSelector,
    easyRefillExpeditedShippingSelector,
    easyRefillRxsSelector,
    easyRefillRxsToOrderSelector
} from 'state/easy-refill/easy-refill.selectors';
import PageSection from 'ui-kit/page-section/page-section';
import InternalHeader from 'components/internal-header/internal-header.component';
import { PrescriptionInformation } from 'components/easy-refill/prescription-information.component';
import {
    easyRefillRemoveRxToRefillRoutine,
    easyRefillStartOrderRoutine,
    easyRefillSubmitOrderRoutine
} from 'state/easy-refill/easy-refill.reducer';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { useEasyRefill } from 'hooks/useEasyRefill';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { EasyRefillErrorModal } from '..';
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

const EasyRefillReview = ({ data }: { data: GatsbyTypes.EasyRefillReviewDataQuery }) => {
    const { blueSkyBackground } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isRemoveBusy, setIsRemoveBusy] = useState<boolean>(false);

    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillOrderFailed'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmitOrderClick = () => {
        setIsSubmitting(true);
        dispatch(
            easyRefillSubmitOrderRoutine.trigger({
                onSuccess: () => {
                    setIsSubmitting(false);
                    navigate('/easy-refill/order-confirmation');
                },
                onFailure: () => {
                    setIsSubmitting(false);
                    handleShowErrorModal(t('pages.easyRefill.review.errors.orderError'));
                }
            })
        );
    };

    const adjudicatedRxs = useSelector(easyRefillAjudicatedRxsSelector);
    const easyRefillRxs = useSelector(easyRefillRxsSelector);
    const expeditedShipping = useSelector(easyRefillExpeditedShippingSelector);
    const easyRefillRxsToOrder = useSelector(easyRefillRxsToOrderSelector);

    const handleGoBack = useCallback(() => {
        navigate('/easy-refill/prescriptions');
    }, []);

    const handleRemoveRx = useCallback(
        (rxNumber) => {
            setIsRemoveBusy(true);
            dispatch(
                easyRefillRemoveRxToRefillRoutine.trigger({
                    rxNumber,
                    onSuccess: () => {
                        dispatch(
                            easyRefillStartOrderRoutine.trigger({
                                onSuccess: () => {
                                    setIsRemoveBusy(false);
                                },
                                onFailure: () => {
                                    setIsRemoveBusy(false);
                                }
                            })
                        );
                    }
                })
            );
        },
        [dispatch]
    );

    const { estimatedRxCost, showBirdiPricing } = useEasyRefill();

    useEffect(() => {
        if (showBirdiPricing) {
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: easyRefillRxsToOrder,
                    forceBirdiInsurance: ALLOW_INSURED_BIRDI_PRICE
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, showBirdiPricing]);

    const { drugDiscountPrices } = useSelector(drugSelector);

    const hasAdjudicatedRxs = Object.values(adjudicatedRxs).length > 0;

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Easy Refill' }}
            suppressApplicationPage={true}
        >
            <PageSection>
                <Container fluid className={`easy-refill-cart`}>
                    <InternalHeader
                        title={t('pages.easyRefill.review.headlineText')}
                        eyebrowText={t('pages.easyRefill.review.eyebrowText')}
                        sectionIndex={0}
                    />
                    <Row className="mb-5">
                        <Col className="d-flex flex-column justify-content-center align-items-center ">
                            <div className="spacer" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <PrescriptionContentSectionHeader
                                label={t('pages.easyRefill.review.sectionHeaders.prescriptionsSelected')}
                                className="easy-refill-layout-content-section-header"
                            />
                            {!hasAdjudicatedRxs ? (
                                <div className="d-flex flex-column justify-content-center no-prescriptions">
                                    <p className="text-center">{t('pages.easyRefill.review.noPrescriptions')}</p>
                                    <p className="text-center">
                                        <Button
                                            onClick={handleGoBack}
                                            variant="outline-primary"
                                            type="button"
                                            label={t('pages.easyRefill.review.backToPrescriptions')}
                                        />
                                    </p>
                                </div>
                            ) : (
                                <PrescriptionInformation
                                    t={t}
                                    adjudicatedRxs={adjudicatedRxs}
                                    prescriptions={easyRefillRxs}
                                    handleRemoveRx={handleRemoveRx}
                                    removingRx={isRemoveBusy}
                                    birdiPrices={drugDiscountPrices}
                                />
                            )}
                        </Col>
                    </Row>
                    {hasAdjudicatedRxs && (
                        <>
                            <Row>
                                <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                    <CartTotal
                                        t={t}
                                        hasUnknownPrice={true}
                                        initialOrderPrice={estimatedRxCost}
                                        currentShippingPrice={expeditedShipping ? EXPEDITED_SHIPPING_COST : 0}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                    <Row className="mt-4">
                                        <Col className="easy-refill-cart-disclaimer">
                                            <p>
                                                <sup>*</sup> {t('pages.easyRefill.review.disclaimerLineOne')}
                                            </p>
                                            <p>{t('pages.easyRefill.review.disclaimerLineTwo')}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={{ span: 10, offset: 1 }}
                                    className="d-flex flex-column flex-lg-row justify-content-lg-end align-items-center easy-refill-cart-ctas"
                                >
                                    <Button
                                        onClick={handleGoBack}
                                        variant="text"
                                        type="button"
                                        label={t('pages.easyRefill.review.backToPrescriptions')}
                                        className="mt-3 mt-md-0 p-0 btn-remove back-to-prescriptions-btn"
                                    />
                                    <Button
                                        async
                                        onClick={handleSubmitOrderClick}
                                        variant="primary"
                                        type="button"
                                        label={t('pages.easyRefill.review.submit')}
                                        disabled={isSubmitting}
                                        isBusy={isSubmitting}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillReview, 'easy-refill');

export const query = graphql`
    query EasyRefillReviewData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/transfer-rx-health.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
